.modal {
  cursor: default;
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(68 92 115 / 90%);
  display: flex;
  justify-content: center;
}

.imageModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background-color: black;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 1920px;
    height: 800px;
  }

  .column {
    position: relative;
  }
}

.clickArea {
  position: absolute;
  cursor: pointer;
}

.object {
  transition: all 1s ease;
  filter: brightness(1);
}

.object:hover {
  filter: brightness(2);
}

.inputContainer {
  position: absolute;
}

.topMenuContainer {
  position: absolute;
  top: -100px;
  right: 50px;
}

.gameController {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: -240px;
  left: -1px;
  z-index: 999;

  .controls {
    top: 594px;
    left: 126px;
    position: absolute;
    transform: scale(1.4);
    img {
      max-width: inherit;

      &:active {
        filter: brightness(2);
      }
    }

    img:nth-child(1) {
      top: -72px;
      left: 10px;
      position: absolute;
    }

    img:nth-child(2) {
      left: 42px;
      position: absolute;
    }

    img:nth-child(3) {
      top: 28px;
      left: 10px;
      position: absolute;
    }

    img:nth-child(4) {
      left: -63px;
      position: absolute;
    }
  }

  .buttons {
    top: 588px;
    left: 395px;
    position: absolute;
    transform: scale(1.4);

    img {
      max-width: inherit;

      &:active {
        filter: brightness(2);
      }
    }

    img.buttonA {
      top: -37px;
      left: 5px;
      position: absolute;
    }

    img.buttonY {
      left: 42px;
      position: absolute;
    }

    img.buttonB {
      top: 34px;
      left: 5px;
      position: absolute;
    }

    img.buttonX {
      left: -32px;
      position: absolute;
    }
  }

  .buttonError {
    display: block;
    margin-top: 485px;
    margin-left: 33px;
    position: absolute;
    z-index: 9999;
  }

  .successVideo {
    position: fixed;
    z-index: 99999999;
    width: 560px;
    bottom:0;
    opacity: 1;
    animation: pulsing 0.5s infinite;
    height: 840px;
    top: 0;
    left: 0;
    object-fit: cover;
    background-size: cover;
  }
  .goalInputText {
    width: 522px;
    margin: 20px;
    position: fixed;
    top: 0;
    z-index: 999999999;
    animation: fade-in-goal 10s;
    textarea {
      position: absolute;
      top: 20px;
      left:20px;
      width: 470px;
      background: none;
      border: none;
      resize: none;
      color: #9EDFFF;
      text-align: center;
    }
  }

  .show {
    display: block;
    position: sticky;
  }
  .hide {
    display: none;
  }
}

@keyframes fade-in-goal {
  0% { opacity: 0; }
  40% { opacity: 0; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

@import "colors";

html,
body {
  height: 100%;
  background-color: black;
}

#root {
  width: 100%;
  height: 100%;

  .debug {
    box-shadow: 0 0 60px 20px #ff083b;
  }

  &.fullScreen {
    background: rgb(255 255 255);
    background: linear-gradient(
      180deg,
      rgb(255 255 255 / 100%) 0%,
      rgb(92 163 245 / 100%) 100%
    );
  }
}

#modal-root {
  > div {
    cursor: default;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
  }
}

input {
  border-radius: 5.744px;
  border: 2.872px solid #05FD99;
  background: #1A9354;
  box-shadow: 11.48718px 11.48718px 5.74359px 0px rgba(0, 0, 0, 0.12) inset;
  padding: 14px 25px 14px 25px;
  box-sizing: border-box;
}

button[type=submit] {
  background: linear-gradient(180deg, #00D671 0%, #00FF87 100%);
  box-shadow: 11.48718px 11.48718px 0px 0px #005930;
  box-sizing: border-box;
  width: 100%;
  padding: 14px 25px 14px 25px;
  border: none;
  text-align: left;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #38343A;

  &:disabled {
    opacity: 0.4;
  }
}

h1 {
  color: #00FF87;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 104.1%;
  text-transform: uppercase;
}

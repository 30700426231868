.roomLights {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  .roomLightsCarousel {
    width: 200%;
    display: flex;
    height: 100%;
    transition: all 0.5s ease-in;
    margin: 0;

    &.move {
      margin-left: -560px;
    }
  }
}

.roomLightsPanel {
  width: 560px;
  margin: 0;
  transition: all 0.5s ease-in;
  height: 100%;

  .indicators {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;


    .indicator {
      width: 150px;

      .indicatorLight {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100px;
      }
    }
  }

  .message {
    padding: 10px;
    text-align: center;
    height: 25px;
  }

  .switch {
    width: 382px;
    position: relative;
    margin: 20px auto;

    .switchBg {
      position: absolute;
      top:0;
      left: 0;
    }

    .switchHandle {
      position: absolute;
      top:70px;
      left: 18px;
      cursor: pointer;
      transform: rotate(-45deg);
      transform-origin: center;
      transition: all 0.3s ease-in;

      &.top {
        transform: rotate(0);

      }

      &.right {
        transform: rotate(45deg);
      }

      &.shake {
        animation: tilt-shaking 0.1s infinite;
      }
    }
  }
}

.roomLightsForm {
  width: 560px;
  position: relative;
  height: 100%;

  .back {
    padding-left: 40px;
    padding-top: 10px;
  }

  .panel {
    margin: 95px 50px 20px 50px;
    padding: 50px;
    border: 6px solid #00FF87;
    min-height: 130px;
    display: flex;
    flex-direction: column;


    .code {
      display: flex;
      align-items: center;
      min-height: 80px;

      p {
        text-transform: uppercase;
        flex-grow: 1;
        margin: 0;
      }
      img {
        flex-grow: 0;
      }
    }

    input {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

@keyframes tilt-shaking {
  0% { margin-left: 0px }
  25% { margin-left: -5px }
  50% { margin-left: 0px }
  75% { margin-left: 5px }
  100% { margin-left: 0px }
}

.imageAsset {
  position: absolute;
  pointer-events: none;
  &.static{
    position: fixed;
  }

  &.pulsating {
    animation: pulsingAnimation 2s infinite;
  }
}


@keyframes pulsingAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.application {
  transform-origin: top left;
  width: 560px;
  height: 840px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;

  &.debug {
    box-shadow: 0 0 50px 11px #8a0909;
  }
}

.topMenuContainer {
  position: absolute;
  top: -100px;
  right: 50px;
}

.errorOverlay {
  background: radial-gradient(50% 50% at 50% 50%, rgba(230, 79, 105, 0.00) 58%, rgba(230, 79, 105, 0.41) 100%);
  position: fixed;
  top:0;
  left:0;
  width: 560px;
  bottom:0;
  opacity: 1;
  animation: pulsing 0.5s infinite;
  height: 840px;
  z-index: 9999999999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .animation {
    width: 250px;
    height: 250px;
  }
}

@keyframes pulsing {
  0% { opacity: 1 }
  50% { opacity: 0.6 }
  100% { opacity: 1 }
}

.mazeMapDetailPage {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #11181E 0%, #1B242D 14.06%, #293947 28.65%, #2A3A49 65.1%, #19222B 82.81%, #172028 100%);  transform: translateY(100%);
  transition: transform ease-in 0.3s;

  &.open {
    transform: translateY(0%);
  }

  .goalInputText {
    width: 522px;
    margin: 20px;
    position: relative;

    textarea {
      position: absolute;
      top: 20px;
      left:20px;
      width: 470px;
      background: none;
      border: none;
      resize: none;
      color: #9EDFFF;
      text-align: center;
    }
  }

  .mazeNumber {
    top: 140px;
    right:40px;
    color: #9EDFFF;
    position: absolute;
    font-size: 1.5rem;
  }

  .mazeAsset {
    position: absolute;
    top:160px;
    left:40px
  }
  .mapMenu{
    position: absolute;
    bottom: 40px;
    left: 0;
    z-index: 99;
    width: 100%;
  }
}

:root {
  --red: 255, 191, 206;
  --green: 106, 249, 184;
  --blue: 125, 233, 237;
  --yellow: 253, 240, 142;
  --orange: 253, 127, 35;
  --light-blue: 45, 255, 254;
  --pink: 252, 40, 252;
  --maroon: 163, 43, 46;
  --purple: 127, 15, 126;
  --white: 255, 255, 255;
  --grey: 166, 166, 166;
  --light-green: 41, 253, 47;
  --beige: 189, 182, 111;
  --dark-blue: 3, 14, 136;
  --teal: 17, 128, 127;
  --dark-pink: 252, 34, 147;
  --flow-game-height: 80vh;
  --flow-game-width: 80vw;
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--width), 1fr);
  grid-template-rows: repeat(var(--height), 1fr);
  --unit: min(calc(--flow-game-height / var(--height)), calc(--flow-game-width / var(--width)));
  -webkit-user-select: none;
  user-select: none;
}

.cell {
  aspect-ratio: 1;
  background-image: radial-gradient(
                  ellipse at center,
                  transparent 0%,
                  transparent 13%,
                  rgba(1, 55, 24, 0.8) 13%
  );
  border: 1px solid #35E793;
  box-sizing: border-box;
  height: var(--unit);
  position: relative;

  &[data-final] {
    background-image: radial-gradient(
                    ellipse at center,
                    transparent 0%,
                    transparent 13%,
                    rgba(1, 55, 24, 1) 13%
    );
  }

  &.point {
    background-image: radial-gradient(
                    ellipse at center,
                    transparent 0%,
                    transparent 34%,
                    rgba(1, 55, 24, 0.8) 35%
    );

    &[data-final] {
      background-image: radial-gradient(
                      ellipse at center,
                      transparent 0%,
                      transparent 30%,
                      transparent 30%,
                      rgba(1, 55, 24, 1) 30%
      );
    }
  }

  &::after,
  &::before {
    content: '';
    background-color: inherit;
    display: block;
    position: absolute;
  }

  &[data-connections~='t']::before {
    width: 20%;
    height: 50%;
    left: 40%;
    top: -1px;
  }

  &[data-connections~='b']::before {
    width: 20%;
    height: 50%;
    left: 40%;
    bottom: -1px;
  }

  &[data-connections~='l']::before {
    width: 50%;
    height: 20%;
    left: -1px;
    top: 40%;
  }

  &[data-connections~='r']::before {
    width: 50%;
    height: 20%;
    right: -1px;
    top: 40%;
  }

  &[data-connections~='t'][data-connections~='b'] {
    &::before {
      width: 20%;
      height: calc(100% + 2px);
      left: 40%;
      top: -1px;
    }
  }

  &[data-connections~='l'][data-connections~='r'] {
    &::before {
      width: calc(100% + 2px);
      height: 20%;
      left: -1px;
      top: 40%;
    }
  }

  &[data-connections~='t'][data-connections~='l'] {
    &::before {
      width: 20%;
      height: 50%;
      left: 40%;
      top: -1px;
    }

    &::after {
      width: 50%;
      height: 20%;
      left: -1px;
      top: 40%;
    }
  }

  &[data-connections~='t'][data-connections~='r'] {
    &::before {
      width: 20%;
      height: 50%;
      left: 40%;
      top: -1px;
    }

    &::after {
      width: 50%;
      height: 20%;
      right: -1px;
      top: 40%;
    }
  }

  &[data-connections~='b'][data-connections~='l'] {
    &::before {
      width: 20%;
      height: 50%;
      left: 40%;
      top: auto;
      bottom: -1px;
    }

    &::after {
      width: 50%;
      height: 20%;
      left: -1px;
      top: 40%;
    }
  }

  &[data-connections~='b'][data-connections~='r'] {
    &::before {
      width: 20%;
      height: 50%;
      left: 40%;
      top: auto;
      bottom: -1px;
    }

    &::after {
      width: 50%;
      height: 20%;
      right: -1px;
      top: 40%;
    }
  }

  &.red {
    background-color: rgb(var(--red));
  }

  &.green {
    background-color: rgb(var(--green));
  }

  &.blue {
    background-color: rgb(var(--blue));
  }

  &.yellow {
    background-color: rgb(var(--yellow));
  }

  &.orange {
    background-color: rgb(var(--orange));
  }

  &.light-blue {
    background-color: rgb(var(--light-blue));
  }

  &.pink {
    background-color: rgb(var(--pink));
  }

  &.maroon {
    background-color: rgb(var(--maroon));
  }

  &.purple {
    background-color: rgb(var(--purple));
  }

  &.white {
    background-color: rgb(var(--white));
  }

  &.grey {
    background-color: rgb(var(--grey));
  }

  &.light-green {
    background-color: rgb(var(--light-green));
  }

  &.beige {
    background-color: rgb(var(--beige));
  }

  &.dark-blue {
    background-color: rgb(var(--dark-blue));
  }

  &.teal {
    background-color: rgb(var(--teal));
  }

  &.dark-pink {
    background-color: rgb(var(--dark-pink));
  }
}

.touch-highlight {
  border-radius: 50%;
  --opacity: 0.4;
  position: absolute;
  height: calc(1.5 * var(--unit));
  pointer-events: none;
  transform: translate(-50%, -50%);
  --unit: min(calc(--flow-game-height / var(--height)), calc(--flow-game-width / var(--width)));
  width: calc(1.5 * var(--unit));
  z-index: 2;

  &.good {
    --opacity: 0.6;
  }

  &.invalid {
    --opacity: 0.1;
  }

  &.red {
    background-color: rgba(var(--red), var(--opacity));
  }

  &.green {
    background-color: rgba(var(--green), var(--opacity));
  }

  &.blue {
    background-color: rgba(var(--blue), var(--opacity));
  }

  &.yellow {
    background-color: rgba(var(--yellow), var(--opacity));
  }

  &.orange {
    background-color: rgba(var(--orange), var(--opacity));
  }

  &.light-blue {
    background-color: rgba(var(--light-blue), var(--opacity));
  }

  &.pink {
    background-color: rgba(var(--pink), var(--opacity));
  }

  &.maroon {
    background-color: rgba(var(--maroon), var(--opacity));
  }

  &.purple {
    background-color: rgba(var(--purple), var(--opacity));
  }

  &.white {
    background-color: rgba(var(--white), var(--opacity));
  }

  &.grey {
    background-color: rgba(var(--grey), var(--opacity));
  }

  &.light-green {
    background-color: rgba(var(--light-green), var(--opacity));
  }

  &.beige {
    background-color: rgba(var(--beige), var(--opacity));
  }

  &.dark-blue {
    background-color: rgba(var(--dark-blue), var(--opacity));
  }

  &.teal {
    background-color: rgba(var(--teal), var(--opacity));
  }

  &.dark-pink {
    background-color: rgba(var(--dark-pink), var(--opacity));
  }
}

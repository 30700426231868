.progress {
  position: absolute;
  cursor: pointer;
  transition: all 1s ease;
  filter: brightness(1);

  &:hover {
    filter: brightness(2);
  }
}

.menu {
  height: 150px;
  width: 100%;

  .menuButton {
    background: transparent;
    border: 0;
    position: absolute;
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
    }
  }

  .progressPanel {
    position: absolute;

    .bgAsset {
      position: absolute;
    }

    .progressBattery {
      position: absolute;
      top: 13px;
      left: 30px;
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;

      .time {
        text-align: right;
        display: block;
        width: 85px;
        color: #fff;
        font-size: 28.621px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .smallLabel {
          font-size: 20px;
          margin-right: 10px;
          margin-left: 5px;
        }
      }
    }
  }
}

.gameControllerDetailPage {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(138deg, #013d21 0%, #00170c 105.85%);
  transform: translateY(100%);
  transition: transform ease-in 0.3s;

  &.open {
    transform: translateY(0%);
  }

  .controller {
    position: absolute;
    top: 130px;
    left: 25px;
  }

  .buttonA {
    position: absolute;
    top: 229px;
    left: 362px;
  }

  .buttonB {
    position: absolute;
    top: 299px;
    left: 362px;
  }

  .buttonX {
    position: absolute;
    top: 263px;
    left: 325px;
  }

  .buttonY {
    position: absolute;
    top: 263px;
    left: 397px;
  }

  .line {
    position: absolute;
    top: 320px;
    left: 420px;
  }

  .callToAction {
    position: absolute;
    top: 580px;
    left: 80px;
    width: 400px;

    p {
      text-transform: uppercase;
      text-align: center;
    }
  }

  .gameControllerMenu {
    position: absolute;
    bottom: 40px;
    left: 0;
    z-index: 99;
    width: 100%;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animateFlicker {
  animation: flickerAnimation 0.5s infinite;
  animation: flickerAnimation 0.5s infinite;
  animation: flickerAnimation 0.5s infinite;
  position: absolute;
  pointer-events: none;
}

.questionFormCarouselContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  height: 100%;

  .questionFormCarousel {
    width: 560px;
    overflow: hidden;

    .questionFormContainer {
      width: 600%;
      display: flex;
      transform: translateX(0);
      transition: all 0.5s ease-in;
    }

    .questionFormSlide {
      width: 560px;
    }

    .form {
      margin: 100px 40px;
      padding: 40px;
      background: rgba(23, 157, 82, 0.37);

      h2 {
        font-size: 1.4rem;
        color: #00FF87;
        text-transform: uppercase;
      }

      textarea {
        min-height: 200px;
        border-radius: 5.744px;
        border: 2.872px solid #05FD99;
        background: #1A9354;
        box-shadow: 11.48718px 11.48718px 5.74359px 0px rgba(0, 0, 0, 0.12) inset;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 10px;

        &::placeholder {
          font-style: italic;
          color: rgba(0, 255, 135, 0.66);
        }
      }

      .submit {
        padding: 25px;
      }
    }
  }
  .formNavigation {
    display: flex;
    position: relative;

    .previous {
      font-size: 0;
      margin-right: 20px;
    }
  }
}

.container {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.textInput {
  margin-top: 44px;
  height: 45px;
  font-size: 22px;
  font-weight: bold;
  border: none;
  padding: 0 10px;
  width: calc(100% - 20px);
  margin-bottom: 16px;
  background-color: transparent;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
  color: #38343a;
  text-align: center;

  &.error {
    transform-origin: left center;
    animation: error-animate 5s linear;
    color: #bc3e22;
  }

  &.success {
    color: white;
    background-color: rgb(133 178 71 / 67%);
  }
}

@keyframes error-animate {
  0% {
    transform: scaleX(1);
    background-color: #bc3e22;
  }

  100% {
    transform: scaleX(0);
    background-color: #bc3e22;
  }
}

.codeSubmit {
  position: absolute;
  cursor: pointer;
}

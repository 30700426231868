
*:not(input) {
  user-select: none;
}

body {
  margin: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

textarea:focus,
input:focus {
  outline: none;
}

button {
  background: none;
  box-shadow:none;
  box-sizing: border-box;
  width: auto;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
}

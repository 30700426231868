.unlockAnimation {
  position: absolute;
  pointer-events: none;
  color: white;
  text-transform: uppercase;

   p {
     color: white;
     font-weight: 700;
     font-size: 48px;
     font-family: Space Grotesk, serif ;
     text-decoration: underline;
     animation: pulsingAnimation 3s infinite;
     transform: translate(-50%, -50%);
     display: block;
     text-align: center;
   }

  &.isClickable {
    pointer-events: inherit;
  }

  @keyframes pulsingAnimation {
    0% {
      transform: scale(1) rotate(0);
    }

    50% {
      transform: scale(2) rotate(12deg);;

    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }
}

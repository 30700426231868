.popop {
  background: rgba(2, 69, 37, 0.80);
  position: fixed;
  top:0;
  left:0;
  width: 560px;
  bottom:0;
  opacity: 1;
  height: 840px;
  z-index: 99999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .panel {
    width: 480px;
    min-height: 217px;
    flex-shrink: 0;
    border: 4px solid #35E793;
    background: linear-gradient(149deg, rgba(0, 0, 0, 0.20) -8.32%, rgba(0, 0, 0, 0.00) 81.06%), linear-gradient(180deg, #018C4A 0%, rgba(0, 191, 101, 0.58) 100%);
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 50px;
      .animation {
        flex-grow: 1;
      }
      .spacer {
        width: 50px;
      }
      button[type=submit] {
        width: 200px
      }
    }
  }
}

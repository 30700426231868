.roomContainer {
  width: 560px;
  height: 840px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.room {
  height: 840px;
  position: relative;

  .roomAsset {
    height: 840px;
    -webkit-user-drag: none;
    user-drag: none;
  }
}

.activitiesShooter {
  margin: 25px;
}
.activitiesShooterHeader {
  display: flex;
  flex-direction: row;
  padding: 17px 25px;
  color: #00FF87;
  font-family: Space Grotesk, serif ;
  font-size: 28.621px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(177deg, rgba(53, 231, 147, 0.23) -72.13%, rgba(53, 231, 147, 0.00) 142.36%);
  margin-right: 10px;
  .activitiesShooterTimer {
    flex-grow: 1;
  }

}

.shooterContainer {
  position: relative;

  .instructions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 300px;
    text-align: center;
  }

  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 500px;
    height: 500px;
    overflow: hidden;

  }
  .fallingWord {
    color: #00FF87;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Space Grotesk, serif;
    font-size: 28.621px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    transition: top 3.5s linear;
    cursor: pointer;

     img {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translateX(-50%) translateY(-50%);
     }

    &.hit {
      display: none;
    }
  }
}

.smiley {
  position: absolute;
  top: 568px;
  right:80px;
  opacity: 0.3;

  &.success {
    opacity: 1;
  }
}
.errorIcon {
  position: absolute;
  top: 568px;
  left:70px;
  opacity: 0.3;
  &.error {
    opacity: 1;
  }
}

.bsutton {
  width: 100%;
  text-align: left;
  padding: 23px 75px;
  background: linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
  box-shadow: 11.48718px 11.48718px 0px 0px #006033;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  text-transform: uppercase;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -12px;
    left: 25px;
    width: 25px;
    height: 25px;
    border: 5px solid rgba(0, 255, 135, 1);
  }

  &.selected {
    background: linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00FF87;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 25px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: rgba(0, 202, 107, 1);
    }
  }
}


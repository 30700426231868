.animation {
  position: absolute;
  pointer-events: none;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animateFlicker {
  animation: flickerAnimation 1s infinite;
  position: absolute;
  pointer-events: none;
}

.animateFlickerSlow {
  animation: flickerAnimation 3s infinite;
  position: absolute;
  pointer-events: none;
}

.wordPulseHint {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  padding: 25px;

}
.wordPulseHintHeader {
  display: flex;
  flex-direction: row;
  padding: 17px 25px;
  color: #00FF87;
  font-family: Space Grotesk, serif ;
  font-size: 28.621px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(177deg, rgba(53, 231, 147, 0.23) -72.13%, rgba(53, 231, 147, 0.00) 142.36%);
  margin-right: 10px;
  .wordPulseHintTimer {
    flex-grow: 1;
  }

}

.hintContainer {
  position: relative;

  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 500px;
    height: 500px;
    overflow: hidden;

  }
  .pulsingWord {
    position: absolute;
    margin: 85px;
    display: flex;
    width: 330px;
    height: 330px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    animation: pulsingAnimation 3s infinite;
    text-transform: uppercase;

    span {
      color: #00FF87;
      text-align: center;
      font-family: Space Grotesk;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &.red {
      background: radial-gradient(77.58% 77.58% at 50% 50%, rgba(224, 24, 52, 0.83) 0%, rgba(170, 18, 39, 0.00) 100%);    }
    &.yellow {
      background: radial-gradient(66.36% 66.36% at 50% 50%, #C3A626 0%, rgba(195, 166, 38, 0.64) 36.46%, rgba(195, 166, 38, 0.00) 100%);
    }
    &.green {
      background: radial-gradient(66.36% 66.36% at 50% 50%, #18A03E 0%, #18A23F 36.46%, rgba(24, 162, 63, 0.00) 100%);    }
    &.blue {
      background: radial-gradient(77.58% 77.58% at 50% 50%, #4A6388 0%, rgba(74, 99, 137, 0.77) 30.21%, rgba(76, 101, 139, 0.00) 100%);
    }
    &.hide {
      display: none;
    }
  }

  .restart {
    position: absolute;
    top:225px;
    left: 230px;
  }
}

@keyframes pulsingAnimation {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.8;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}


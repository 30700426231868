.scene {
  cursor: default;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;

}

.clickArea {
  position: absolute;
  cursor: pointer;
  transition: all 1s ease;

  &.pulsing {
    animation: pulsingAnimation 2s infinite;
  }

  &:hover {
    animation: none;
    opacity: 1;
  }
}

.sceneMenu {
  position: absolute;
  bottom: 40px;
  left: 0;
  z-index: 99;
  width: 100%;
}


@keyframes pulsingAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}


//.object {
//  transition: all 1s ease;
//  opacity: 1;
//}
//
//.object:hover {
//  opacity: 0.3;
//}

.windowMinigameContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  height: 100%;

  .questionCarousel {
    width: 560px;
    overflow: hidden;

    .questionContainer {
      width: 1000%;
      display: flex;
      transform: translateX(0);
      transition: all 0.5s ease-in;
    }

    .question {
      width: 560px;

      b{
        color: #FFF494;
      }
    }

    .resultScreen {
      width: 560px;
      box-sizing: border-box;
      padding-top: 100px;
      padding-left: 60px;
      position: relative;

      .result {
        position: absolute;
        top: 150px;
        width: 400px;
        left: 80px;
        text-align: center;
        img {
          margin-top: 50px;
        }
        p {
          margin-top: 0;
        }
      }
    }

    p {
      margin-top: 75px;
      padding: 50px;
      box-sizing: border-box;
      text-transform: uppercase;
    }

    .answers {
      padding: 0 0 0 50px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;

      button {
        margin-right: 30px;
        position: relative;
        width: 230px;
        text-align: left;
        color: #00592F;
        span {
          position: absolute;
          top: 85px;
          left: 30px;
          text-transform: uppercase;
        }
      }
    }
  }
  .questionNavigation {
    display: flex;
    margin: 20px 40px 0 40px;
    .filler {
      flex-grow: 1;
    }
  }
  .questionCount {
    opacity: 0.5;
    width: 100%;
    text-align: center;
    p {
      width: 100%;
      text-align: center;
    }
  }
}

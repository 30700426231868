.container {
  width: 366px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  .numberInput {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .panel {
    position: relative;;
    cursor: pointer;

    .symbolAsset {
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 32px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 103px;
      height: 103px;

      &.selected {
        background: linear-gradient(0deg, rgba(255, 244, 148, 0.78) 0%, rgba(255, 244, 148, 0.78) 100%), linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
      }
      &.error {
        background: linear-gradient(0deg, rgba(255, 148, 160, 0.78) 0%, rgba(255, 0, 0, 0.22) 100%), linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
      }
      &.success {
        background: linear-gradient(0deg, rgba(18, 255, 0, 0.78) 0%, rgba(88, 236, 15, 0.44) 100%), linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
      }
    }
  }

  .panel:nth-child(2) {
    .symbolAsset {
      top: 18px;
      left: 33px;
    }
  }

  .panel:nth-child(4) {
    .symbolAsset {
      top: 27px;
      left: 27px;
    }
  }

  .panel:nth-child(5) {
    .symbolAsset {
      top: 30px;
      left: 33px;
    }
  }

  .panel:nth-child(6) {
    .symbolAsset {
      top: 38px;
      left: 29px;
    }
  }



  .submit {
    margin-top: 20px;
    margin-right: 55px;
    padding: 35px;
    width: 336px
  }
}


@keyframes error-animate {
  0% {
    transform: scaleX(1);
    background-color: #bc3e22;
  }

  100% {
    transform: scaleX(0);
    background-color: #bc3e22;
  }
}



.activitiesContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  height: 100%;

  .activityCarousel {
    width: 560px;
    overflow: hidden;
    height: 100%;

    .activityPageContainer{
      width: 400%;
      display: flex;
      transform: translateX(0);
      transition: all 0.5s ease-in;
      height: 100%;
    }

    .activityPage {
      width: 560px;
      height: 100%;
      position: relative;
    }

    .symbolInput,
    .codeInput {
      position: relative;
      width: 350px;
      margin: 90px auto;
      background: rgba(23, 157, 82, 0.37);
      padding: 30px;
    }
    .symbolInput {
      margin-top: 100px;
      height: 490px;
    }
  }

  .activityListContainer {
    overflow: auto;
    padding: 42px 50px 242px;
    height: 100%;
    box-sizing: border-box;

    .button {
      width: 100%;
      text-align: left;
      padding: 23px 75px;
      background: linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
      box-shadow: 11.48718px 11.48718px 0px 0px #006033;
      position: relative;
      margin-bottom: 20px;
      cursor: pointer;
      text-transform: uppercase;
      box-sizing: border-box;
      color: #38343A;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -12px;
        left: 25px;
        width: 25px;
        height: 25px;
        border: 5px solid rgba(0, 255, 135, 1);
      }

      &.selected {
        background: linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00FF87;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -12px;
          left: 25px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: rgba(0, 202, 107, 1);
        }
      }
    }
  }
}


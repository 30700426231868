.container {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 120px;
  padding: 25px 20px 35px 20px;
  background: rgba(23, 157, 82, 0.37);
  margin: 0 80px 0 80px;
}

.lockIcon {
  margin-top: 150px;
  margin-bottom: 120px;
  height: 50px;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  .dotContainer {
    width: 33%;
    height: 116px;
  }
}

.panel {
  position: relative;;
  cursor: pointer;
  span {
    position: absolute;
    top: 30px;
    left: 43px;
    font-size: 32px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 103px;
    height: 103px;

    &.selected {
      background: linear-gradient(0deg, rgba(255, 244, 148, 0.78) 0%, rgba(255, 244, 148, 0.78) 100%), linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
    }
    &.error {
      background: linear-gradient(0deg, rgba(255, 148, 160, 0.78) 0%, rgba(255, 0, 0, 0.22) 100%), linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
    }
    &.success {
      background: linear-gradient(0deg, #00FF87 0%, rgba(88, 236, 15, 0.44) 100%), linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
    }
  }
}

.submit{
  margin-top: 20px;
  padding: 25px !important;
}

.hintCarouselContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  height: 100%;

  .hintCarousel {
    width: 560px;
    overflow: hidden;

    .hintContainer {
      width: 300%;
      display: flex;
      transform: translateX(0);
      transition: all 0.5s ease-in;
    }

    .hint {
      width: 560px;
    }

    p {
      margin: 95px 50px 20px 50px;
      padding: 50px;
      border: 6px solid #00FF87;
      min-height: 130px;
    }
  }
  .hintNavigation {
    display: flex;
    margin: 0 50px 0 50px;
    position: relative;
    .symbol {
      border: 6px solid rgba(0, 255, 135, 0.21);
      width: 92px;
      height: 92px;
      position: absolute;
      top: 0;
      left: 107px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .filler {
      flex-grow: 1;
    }
  }
}

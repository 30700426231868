.flowGame {
  position: absolute;
  top: 20px;
  left: 25px;
  width: 100%;
  right: 0;
  height: 100%;

  .flowGameBG {
    position: absolute;
    top: 2px;
    left: -5px;
  }

  .flowGameGrid {
    width: 509px;
  }
}

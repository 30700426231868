.chatGame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  height: 100%;

  .questionCarousel {
    width: 560px;
    overflow: hidden;

    .questionContainer {
      width: 900%;
      display: flex;
      transform: translateX(0);
      transition: all 0.5s ease-in;
    }

    .question {
      width: 560px;
      margin-bottom: 40px;
      transition: background 0.3s ease-in;

      .friendLabel {
        color: #058F4E;
        text-transform: uppercase;
      }

      .p,
      p {
        margin: 75px 40px 0 40px;
        font-size: 1.5rem;
        line-height: 1.9rem;
        font-family: "IBM Plex Mono", monospace;
        color: #00FF87;
        text-transform: uppercase;

        padding: 50px;
        background: linear-gradient(0deg, #12B568 0.18%, rgba(15, 147, 85, 0.00) 81.99%), linear-gradient(0deg, #12B568 0.18%, rgba(15, 147, 85, 0.00) 81.99%);
      }

      .textBalloon {
        margin-left: 380px;
        margin-top: -1px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      .answers {
        padding: 0 40px 0 40px;
        button {
          width: 100%;
          text-align: left;
          padding: 23px 75px;
          background: linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
          box-shadow: 11.48718px 11.48718px 0px 0px #006033;
          position: relative;
          margin-bottom: 20px;
          cursor: pointer;
          text-transform: uppercase;
          box-sizing: border-box;
          color: #00592F;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -12px;
            left: 25px;
            width: 25px;
            height: 25px;
            border: 5px solid rgba(0, 255, 135, 1);
          }

          &.selected {
            background: linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00FF87;
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              margin-top: -12px;
              left: 25px;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background-color: rgba(0, 202, 107, 1);
            }
          }

        }
      }

      &.error {
        .answers {
          button {
            color: white;
            background: linear-gradient(180deg, #FE4F6F 0%, #ED4F69 14.06%, #742D42 65.1%, #742F43 82.81%, #743044 100%);

            &::after {
              border: 5px solid #743044;
              background-color:  #743044;
            }
          }
        }
      }
    }

    .questionFormSlide {
      width: 560px;
    }

    .form {
      margin: 100px 40px;
      padding: 40px;

      h2 {
        color: #00FF87;
        text-transform: uppercase;
        font-size: 1.4rem;
      }

      textarea {
        min-height: 200px;
        border-radius: 5.744px;
        border: 2.872px solid #05FD99;
        background: #1A9354;
        box-shadow: 11.48718px 11.48718px 5.74359px 0px rgba(0, 0, 0, 0.12) inset;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 10px;

        &::placeholder {
          font-style: italic;
          color: rgba(0, 255, 135, 0.66);
        }
      }

      .submit {
        padding: 25px;
      }
    }
  }
  .questionNavigation {
    display: flex;
    margin: 20px 40px 0 40px;
    .filler {
      flex-grow: 1;
    }
  }
  .questionCount {
    opacity: 0.5;
    width: 100%;
    text-align: center;
    p {
      width: 100%;
      text-align: center;
    }
  }
}

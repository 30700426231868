.slidePuzzle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  height: 100%;

  .pulsingButton {
    background: white;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    bottom: -139px;
    transform: scale(1);
    left: -16px;
    animation: pulse-button 2s infinite;
  }

  @keyframes pulse-button {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }


  .shuffleContainer {
    width: 200%;
    display: flex;
    transform: translateX(0);
    transition: all 0.5s ease-in;

    .shufflePage {
      width: 560px;
      position: relative;

      .goalListContainer {
        overflow: auto;
        padding: 80px 50px 242px;
        height: 840px;
        box-sizing: border-box;
      }

      textarea {
        min-height: 100px;
        border-radius: 5.744px;
        border: 2.872px solid #05FD99;
        background: #1A9354;
        box-shadow: 11.48718px 11.48718px 5.74359px 0px rgba(0, 0, 0, 0.12) inset;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 10px;

        &::placeholder {
          font-style: italic;
          color: rgba(0, 255, 135, 0.66);
        }
      }
    }
  }

  .button {
    width: 100%;
    text-align: left;
    padding: 23px 75px;
    background: linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00BF65;
    box-shadow: 11.48718px 11.48718px 0px 0px #006033;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    text-transform: uppercase;
    box-sizing: border-box;
    color: #00592F;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 25px;
      width: 25px;
      height: 25px;
      border: 5px solid rgba(0, 255, 135, 1);
    }

    &.selected {
      background: linear-gradient(265deg, rgba(0, 0, 0, 0.20) -29.69%, rgba(0, 0, 0, 0.00) 78.33%), #00FF87;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -12px;
        left: 25px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: rgba(0, 202, 107, 1);
      }
    }
  }
}

.puzzleContainer {
  position: absolute;
  width: 500px;
  margin: 0 auto;
  background: linear-gradient(138deg, #013d21 0%, #00170c 105.85%);
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 500px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);

  &.success {
    background: none;
    box-shadow: none;
  }

  &.notShuffled {
    //filter: blur(8px);
  }

  .tile {
    display: flex;
    flex-basis: calc(33.33%);
    justify-content: center;
    flex-direction: column;
    background-size: 300% 300%;
  }

  .tile.blank {
    display: flex;
    flex-basis: calc(33.33%);
    justify-content: center;
    flex-direction: column;
    background-image: none !important;
  }

  .tile1 {
    background-position: 0 0;
  }

  .tile2 {
    background-position: 50% 0;
  }

  .tile3 {
    background-position: 100% 0;
  }

  .tile4 {
    background-position: 0 50%;
  }

  .tile5 {
    background-position: 50% 50% ;
  }

  .tile6 {
    background-position: 100% 50%
  }

  .tile7 {
    background-position: 0 100%;
  }

  .tile8 {
    background-position: 50% 100%;
  }

  .tile9 {
    background-position: 100% 100% ;
  }

  //.tile10 {
  //  background-position: 33.33% 66.6667%;
  //}
  //
  //.tile11 {
  //  background-position: 66.6667% 66.6667%
  //}
  //
  //.tile12 {
  //  background-position: 100% 66.6667% ;
  //}
  //
  //.tile13 {
  //  background-position: 0 100% ;
  //}
  //
  //.tile14 {
  //  background-position: 33.333% 100%;
  //}
  //
  //.tile15 {
  //  background-position: 66.6667% 100%;
  //}
  //
  //.tile16 {
  //  background-position: 100% 100%;
  //}

  //.tile17 {
  //  background-position: 25% 75%;
  //}
  //
  //.tile18 {
  //  background-position: 50% 75%;
  //}
  //
  //.tile19 {
  //  background-position: 75% 75%;
  //}
  //
  //.tile20 {
  //  background-position: 100% 75%;
  //}
  //
  //.tile21 {
  //  background-position: 0 100%;
  //}
  //
  //.tile22 {
  //  background-position: 25% 100%;
  //}
  //
  //.tile23 {
  //  background-position: 50% 100%;
  //}
  //
  //.tile24 {
  //  background-position: 75% 100%;
  //}
  //
  //.tile25 {
  //  background-position: 100% 100%;
  //}
}



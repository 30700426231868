.redirect {
  position: absolute;
  cursor: pointer;
  transition: all 1s ease;
  filter: brightness(1);

  &:hover {
    filter: brightness(2);
  }

  .object {
    &.dragTarget {
      filter: brightness(2);
    }
  }

  .pulse {
    animation: flickerAnimation 5s infinite;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.8;
  }
}

.roomContainer {
  position: relative;
  width: 560px;
  height: 840px;
  overflow: hidden;
  margin: 0 auto;
}

.imageAsset {
  position: absolute;
  top: 0;
  left: 0;
  width: 840px;
}

.videoAsset {
  position: absolute;

  a {
    color: white;
    text-decoration: none;
    font-size: 14pt;
    position: absolute;
    bottom: 0;
    padding: 5px;
  }
}

.endContainer {
  .endClock {
    position: relative;
    width: 560px;
    height: 840px;
    overflow: hidden;
    margin: 0 auto;
  }

  .toDownload {
    width: 1920px;
    height: 800px;
  }

  .endClockImage {
    position: absolute;
    top: 0;
    left: 0;
  }

  .endClockCounter {
    position: absolute;
    top: 213px;
    left: 180px;
    transform: rotate(10deg);
    opacity: 0.6;
    font-size: 2.3rem;
    h3 {
      opacity: 0.6;
      font-size: 2.3rem;
      font-weight: bold;
    }
  }

  .endClockDownload {
    position: absolute;
    bottom: 100px;
    width: 400px;
    height: 80px;
    left: 80px;
    cursor: pointer;
    color: #00FF87;
    font-size: 1.9rem;
    border: 2px solid #00FF87;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  .resultForm {
    width: 560px;
    min-height: 840px;
    background: linear-gradient(180deg, #00200D 0%, #013E19 14.06%, #01461C 28.65%, #004B1E 65.1%, #012911 82.81%, #012911 100%), #FFF;
    position: absolute;
    left: 0;
    top: 0;
    padding: 30px 50px;
    box-sizing: border-box;
    overflow: auto;

    .brainLogo {
      width: 300px;
      margin: 0 70px 0 90px;
    }

    p{
      font-size: 1.2rem;
      color: #FFF494;

    }
    h2 {
      color: #00FF87;
      font-size: 1.2rem;
      font-style: italic;
      border-bottom: 1px solid #00FF87;
      padding-bottom: 20px;
      span {
        color: #00FF87;
        font-style: normal;
        font-weight: bold;
      }

      &.mainGoal {
        padding-top: 15px;
        font-size: 1.2rem;

        span {
          margin-top: 10px;
          display: block;
        }
      }
    }

    .logo {
      margin: 0 auto;
      display: block;
      z-index: 9999;
    }

    .background {
      width:1425px;
      height: 1386px;
      border-radius: 1425px;
      position: absolute;
      background: radial-gradient(50% 50% at 50% 50%, rgba(73, 255, 169, 0.42) 0%, rgba(73, 255, 169, 0.00) 100%);
      pointer-events: none;
    }
  }
}

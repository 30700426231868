.digitalClock {
  color: red;
  font-family: Orbitron, sans-serif;
  font-size: 19px;

  &.phoneClock {
    color: white;
    font-size: 18px;
    font-family: Roboto, Arial, sans-serif;
    margin-left: 8px;
  }

  &.blue {
    color: #86a0ed;
  }
}

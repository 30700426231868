$color-green: #00ff87;
$color-yellow: #fbb901;
$color-blue: #00b5d4;
$color-dark-blue: #223343;
$color-bg-blue: #3d5164;
$color-gray: #c4c4c4;
$color-gray-light: #f5f5f5;
$color-white: #fff;
$color-extra-dark-blue: #131f2b;
$color-text: #242424;
$color-red: #ff2929;

.startCodeInputContainer {
  position: absolute;
  top: 400px;

  .form {
    margin: 0 30px;
  }

  label,
  p {
    margin: 0 0 20px 0;
    color: #00ea7c;
    padding-bottom: 5px;
    display: block;
    font-size: 24px;
  }

  p {
    margin: 0 30px 10px 30px;

  }


  .inputContainer {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    input {
      width: 100%;
      text-align: center;
    }
  }

  p.error{
    margin-top: 20px;
    color: #bc3e22;;
  }

  .versionNumber {
    position: fixed;
    left: 10px;
    bottom: 10px;
    color: rgba(255, 255, 255, 0.34);
    font-size: 12px;
  }
}

.debugContainer {
  position: fixed;
  top: 0;
  right: 0;

  .debugButton {
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: rgb(255 0 0 / 20%);
  }

  .debugPage {
    background-color: rgb(81 81 81 / 76%);
    position: absolute;
    top: 50px;
    right: 0;
    padding: 20px;
    color: #fffce1;
    width: 560px;
    left: -520px;
    z-index: 9999;
    font-size: 12px;

    .debugToggle {
      font-weight: bold;
      cursor: pointer;
    }

    ul {
      margin-top: 30px;
      margin-left: 30px;
      font-size: 14px;

      li {
        list-style-type: disc;
        font-size: 18px;

      }
    }
  }
}

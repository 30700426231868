.hintContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 0%;
  z-index: 99;
  background-color: rgb(0 0 0 / 0%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  transition: height 0.3s ease-in, background-color 0.8s ease-in;

  .hint {
    background: linear-gradient(
      0deg,
      rgb(162 150 186 / 87%) 0%,
      rgb(243 239 250 / 87%) 100%
    );
    width: 500px;
    min-height: 50px;
    padding: 40px;
    border-radius: 40px;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in;

    .hintHeader {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .hintHeaderIcon {
        flex-grow: 1;
        margin-top: -20px;
        margin-left: -20px;
      }

      .hintHeaderClose {
        margin-top: -20px;
        margin-right: -20px;
        cursor: pointer;
      }
    }

    p {
      margin-top: 20px;
      font-size: 24px;
      font-weight: 400;
      color: black;
      line-height: 36px;
    }

    .hintImage {
      height: 250px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &.show {
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 63%);

    .hint {
      transform: translateY(0);
    }
  }
}
